import React from "react";
import { default as styled } from "styled-components";
import rothenberger from "../images/rothenberger.jpg";

const Image = styled.img`
  max-width: ${(p) => (p.inNews ? "100%" : "50%")};

  @media (max-width: 700px) {
    max-width: 100%;
  }
`;

export const FutesRendszerMosas = (props) => {
  return (
    <div>
      <Image
        className="mb-5"
        src={rothenberger}
        alt="Rothenberger products"
        inNews={!!props.inNews}
      />
      <div>
        <strong>FŰTÉSRENDSZER MOSÁS</strong>
        <br />
        <br />
        <strong>
          Cégünk már rendszer átmosással is foglalkozik, az egyik legkorszerűbb
          Rothenberger géppel.
        </strong>
        <br />
        <br />
        <div>
          Radiátoros és padlófűtésrendszerekhez alkalmas, és új házak
          ívóvízrendszerének fertőtlenítését is végezzük vele!
        </div>
        <div>3 fázisú levegőbuborékos, impulzusos, vegyszeres mosás.</div>
        <div>
          A végén konzerválóanyag bejuttatásával védőréteget képez a cső falain,
          ezzel megakadályozza a nem kívánt lerakódásokat, így hosszabb életet
          biztosít a kazánoknak és a fűtésrendszer elemeinek.
        </div>
        <br />
        <ul>
          <li>Zöld - padlófűtésrendszerekhez</li>
          <li>Narancssárga - radiátoros fűtésrendszerekhez</li>
          <li>Kék - Tömítő és konzerválószer</li>
          <li>Sárga - ivóvízrendszer fertőtlenítéséhez</li>
        </ul>
      </div>
    </div>
  );
};
