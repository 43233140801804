import React, { useState, useEffect } from "react";
import { default as styled } from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faArrowLeft, faArrowRight);

const ArrowIcon = styled(FontAwesomeIcon)`
  color: #666869;
  font-size: 28px;

  @media (max-width: 550px) {
    font-size: 20px;
  }
`;

const DialogLayer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  margin: 50px auto 0 auto;
  max-height: calc(100vh - 100px);
`;

const DialogContent = styled.div`
  width: 80vw;
  height: auto;

  @media (max-width: 550px) {
    width: 70vw;
  }
`;

const DialogPicContainer = styled.div`
  position: relative;
  height: 80vh;
  width: auto;
  max-height: 90vh;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DialogPic = styled.img`
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  border: 4px solid white;
  border-radius: 3px;
`;

const IconContainer = styled.div`
  width: 50px;
  height: 50px;
  background-color: black;
  cursor: pointer;
  border-top-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    > svg {
      color: #ffcb0c;
    }
  }
  @media (max-width: 550px) {
    width: 35px;
    height: 35px;
    border-top-left-radius: 3px;
  }
`;

export const GalleryDialog = props => {
  const [photos, setPhotos] = useState([]);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  useEffect(() => {
    let tempIndex;
    props.photos.map((photo, index) => {
      if (props.photoToShow === photo) {
        tempIndex = index;
      }
    });
    const photosOnTheRight = props.photos.slice(tempIndex);
    const photosOnTheLeft = props.photos.slice(0, tempIndex);

    setPhotos(photosOnTheRight.concat(photosOnTheLeft));
  }, []);

  const goToPreviousPhoto = event => {
    if (currentPhotoIndex !== 0) {
      setCurrentPhotoIndex(currentPhotoIndex - 1);
    } else {
      setCurrentPhotoIndex(photos.length - 1);
    }
    event.stopPropagation();
  };

  const goToNextPhoto = event => {
    if (currentPhotoIndex + 1 < photos.length) {
      setCurrentPhotoIndex(currentPhotoIndex + 1);
    } else {
      setCurrentPhotoIndex(0);
    }
    event.stopPropagation();
  };

  if (!photos || currentPhotoIndex === undefined) {
    return null;
  }
  return (
    <DialogLayer onClick={() => props.onClose()}>
      <MainContainer>
        {photos.length > 1 && (
          <IconContainer onClick={e => goToNextPhoto(e)}>
            <ArrowIcon
              icon={["fa", "arrow-left"]}
              onClick={e => goToPreviousPhoto(e)}
            />
          </IconContainer>
        )}
        <DialogContent onClick={e => e.stopPropagation()}>
          <DialogPicContainer onClick={() => props.onClose()}>
            <DialogPic src={photos[currentPhotoIndex]}  onClick={(e) => e.stopPropagation()}/>
          </DialogPicContainer>
        </DialogContent>
        {photos.length > 1 && (
          <IconContainer onClick={e => goToNextPhoto(e)}>
            <ArrowIcon icon={["fa", "arrow-right"]} />
          </IconContainer>
        )}
      </MainContainer>
    </DialogLayer>
  );
};
