import React, { useState } from "react";
import { css, default as styled } from "styled-components";
import { inject, observer } from "mobx-react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faGithubSquare,
  faLinkedin,
  faCodepen,
  faReact,
  faJs,
  faAngular,
  faNodeJs
} from "@fortawesome/free-brands-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";

library.add(
  faFacebookSquare,
  faGithubSquare,
  faLinkedin,
  faCodepen,
  faEnvelope,
  faFile,
  faReact,
  faJs,
  faAngular,
  faNodeJs
);

const WorkPage = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background: rgb(190, 190, 190); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    rgb(190, 190, 190) 35%,
    rgba(190, 190, 190, 0.6) 50%,
    rgba(190, 190, 190, 0) 85%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    rgb(190, 190, 190) 35%,
    rgba(190, 190, 190, 0.6) 50%,
    rgba(190, 190, 190, 0) 85%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  @media (max-width: 450px) {
    background: rgb(190, 190, 190); /* fallback for old browsers */
  }
`;

const WorkDetails = styled.div`
  display: flex;
  margin: 60px auto 0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 60px 40px;
  width: 100%;
  max-width: 1120px;
  @media (max-width: 550px) {
    padding: 30px;
  }
`;

const MyName = styled.h2`
  font-family: "Michroma", sans-serif;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 36px;
  background-color: rgb(105, 65, 46);
  background: linear-gradient(
    to right,
    rgb(105, 65, 46) 45%,
    rgba(105, 65, 46, 0) 100%
  );
  margin-bottom: 40px;
  width: 100%;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;

  @media (max-width: 764px) {
    margin-bottom: 10px;
  }
  @media (max-width: 550px) {
    font-size: 28px;
  }

  > a {
    font-size: 50px;
  }
`;

const Card = styled.div`
  width: 100%;
  background-color: rgb(240, 240, 240);
  border-radius: 4px;
  padding: 25px;
  margin-bottom: 15px;
  display: ${p => (p.isOpenSection ? "flex" : "none")};
  flex-direction: column;
  transition: background-color 0.1s ease-in;
  box-shadow: 1px 0 10px rgba(30, 30, 30, 0.8);

  :hover {
    background-color: rgb(250, 250, 250);
  }
`;

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  height: 100%;

  > h5 {
    font-weight: 600;
  }
`;

const WorkType = styled.div`
  display: flex;
  flex-direction: row;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: auto;
  background-color: rgb(100, 100, 100);
  align-self: flex-start;
  padding: 15px 25px;
  margin: 10px 0 0;
  line-height: 1em;
  color: ${p => (p.isOpenSection ? "#ffcb0c" : "rgb(240, 240, 240)")};
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  :hover {
    background-color: rgb(80, 80, 80);
    color: #ffcb0c;
    border-top-left-radius: 5px;
  }

  ${p => !p.isOpenSection && "border-bottom-left-radius: 5px;"}
  ${p => !p.isOpenSection && "border-bottom-right-radius: 5px;"}
`;

const ButtonLinkStyled = css`
  min-height: 40px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffcb0c;
  color: rgb(20, 20, 20);
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 1px 1px 3px rgb(90, 90, 90);
  transition: color 0.15s ease-in;
  transition: background-color 0.1s ease-in;
  cursor: pointer;

  :hover {
    text-decoration: none;
    color: #ffcb0c;
    background-color: rgb(90, 90, 90);
    box-shadow: 2px 1px 3px rgb(30, 30, 30);
  }
`;

const FormButton = styled.div`
  ${ButtonLinkStyled};
  margin-top: 15px;
  border: none;
  width: auto;
  justify-self: flex-start;
  font-weight: bold;
  width: 140px;

  &:first-child {
    margin-right: 15px;
  }
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

const WorkList = styled.ul`
  padding-left: 0;
`;

const Detail1 = () => {
  return (
    <div>
      Napjaink legkorszerűbb és legjobb hatásfokkal működő tüzelő berendezései a
      zárt égésterű kondenzációs kazánok. Az égéshez szükséges levegőt külön
      rendszeren keresztül, a szabadból nyerik, nem a lakótérből szívják el az
      oxigént, így jelenleg ezek a legbiztonságosabb gázkészülékek. Az
      égéstermék és a levegő elvezetése mesterséges úton, egy speciális
      rendszeren keresztül történik, az áramlást a készülék belső ventilátora
      biztosítja.
      <br />
      <br />
      <em>
        A kondenzációs kazánok kéménye 2 módon szerelhető, a kéménytől és a
        helyiség adottságaitól függően:
      </em>
      <ul>
        <li>
          Koncentrikus (cső a csőben) rendszer: a füstgáz a kisebb átmérőjű
          csövön távozik, az égéshez szükséges friss levegő pedig a külső csövön
          keresztül áramlik a készülékbe. Előnye: a füstgáz előmelegíti az égési
          levegőt. Hátránya: mivel mindkét irány légáramlását ventilátorok
          biztosítják, ezért hosszabb kéményeknél jobban terhelik a ventilátort.
        </li>

        <li>
          Osztott, szétválasztott rendszer: ebben az esetben az égéstermék egy
          csövön keresztül a kémény kürtőn távozik a szabadba, a friss levegőt
          pedig egy oldalfalról szívja be a kazán, egy másik csövön keresztül.
          Előnye: a levegő beszívási út jóval rövidebb lehet a füstgáz kürtőnél,
          ezért kisebb ventilátor teljesítmény is elég. Szűkebb kéményeknél is
          alkalmazható, ahova a dupla cső nem fér be. Valamint a beáramló levegő
          nem hűti le a füstgázt, ezért kisebb lesz a kondenzvíz képződés.
          Hátránya: nem esztétikus, mert a helyiségen végig kell vezetni az
          égési levegőt biztosító járatot. Azonban ez sok esetben gipszkarton
          elfedéssel orvosolható.
        </li>
      </ul>
      <em>A bélelés anyaga lehet:</em>
      <ul>
        <li>műanyag béléscső</li>
        <li>rozsdamentes merev vagy flexibilis acél</li>
        <li>műgyanta (Furan Flex)</li>
      </ul>
    </div>
  );
};

const Detail2 = () => {
  return (
    <div>
      Azok a készülékek, amelyek a lakótérből veszik el a működéshez szükséges
      oxigént, nyílt égésterűnek minősülnek. Nagyon fontos, hogy a helyiségnek
      mindig meglegyen a levegő utánpótlása, légbevezető beépítése formájában. 1
      m3 földgáz elégéséhez 10 m3 levegőre van szükség, különben a készülék nem
      tud megfelelően működni, mely szénmonoxid visszaáramláshoz vezethet, ami
      életveszélyes lehet. A légbevezetők beépíthetők falba, vagy ablakba is,
      méretét és fajtáját a készülék függvényében határozzák meg.
      <br />
      <br />
      Az égéstermék a kémény huzatának hatására távozik a szabadba, itt nincs
      szükség a rendszer fokozott tömítettségének biztosítására.
      <br />
      <br />
      A nyílt égésterű készülékek kéményét alumíniummal, saválló acéllal, vagy
      Furán Flex-el bélelhetjük.
      <br />
    </div>
  );
};

const Detail3 = () => {
  return (
    <div>
      Nagy szilárdságú, üvegszálakkal erősített műgyanta, mellyel a bélelés
      csupán néhány órát vesz igénybe. A bélelő anyagot képlékeny formában
      húzzák a kéménybe, ezután felfújják, és forró gőzzel kötési hőmérsékletre
      melegítik.
      <br />
      <br />
      Tulajdonságai:
      <br />
      <br />
      <ul>
        <li>Egészségre ártalmatlan</li>
        <li>Gyártó által vállalt garancia 25 év</li>
        <li>Követi az elhúzásos kémények vonalát</li>
        <li>Egy kéményen belül lehet változó keresztmetszetű</li>
        <li>Sima belső felülete javítja a kazán hatásfokát</li>
        <li>
          Vékony falának köszönhetően nem okoz keresztmetszet csökkenést a
          kéményben
        </li>
        <li>Nem éghető</li>
        <li>Egyszerűen kezelhető</li>
        <li>Minden alakú és méretű kéménynél alkalmazható</li>
        <li>Tökéletesen ellenáll a kéményben keletkező savaknak</li>
        <li>Tökéletes gáztömörséget biztosít</li>
        <li>
          Szilárdságának köszönhetően öntartó kémények építésénél is
          alkalmazható
        </li>
      </ul>
      A kéményeken túl, társasházi esővíz elvezető rendszerek korszerűsítésére
      is használható a Furan Flex béléscső:
      <br />
      <br />
      A csapadékelvezetők anyaga általában acél, beton, eternit. A korrózió és
      az épület mozgása ezeket a vezetékeket megrongálja. Javításuk körülményes,
      a falak bontásával jár, azonban Furan Flex technológiával könnyen
      felújíthatóak, falbontás nélkül helyezhetők a csapadék elvezetőkbe.
      Fontos, hogy csak a függőleges esővíz elvezető csövet lehet bélelni, a
      csővezeték alján, és a vízszintes szakaszokon kopásálló idom és bélelés
      használata szükséges.
      <br />
      <br />
      A Furan Flex béléscső alkalmas továbbá társasházi szellőző rendszerek
      felújítására, bélelésére is:
      <br />
      <br />
      Nem ég el, nem korrodál, teljesen tömör, könnyű, nagy szilárdságú, akár 80
      méter szellőző is kibélelhető toldás nélkül, egészségre káros anyagokat
      nem bocsát ki, festhető.
    </div>
  );
};

const Detail4 = () => {
  return (
    <div>
      Ez az új béléscső fafűtéses kazánok kéményeinek és szellőző vezetékek
      belső felületének tűzálló bélelésére lett kifejlesztve. 10 éves
      fejlesztési munkával jött létre.
      <br />
      <br />
      <strong>Anyaga:</strong>
      <ul>
        <li>Üvegszövet, mely a hagyományos Furan Flex-el azonos</li>
        <li>
          Módosított műgyanta keverék, mely vöröses színű, kevésbé folyékony, és
          lassabban keményedő
        </li>
        <li>
          Külső vászon takarja, mely fehér színű üvegselyem szövet, kevlár
          szálakkal erősítve
        </li>
      </ul>
      Nem éghető, koromtűznek, valamint 450 fokos maximális füstgáz
      hőmérsékletnek ellenálló rendszer. 1000 fokos hőmérsékletű füstgáznak 30
      percig ellenáll.
      <br />
      <br />
      KIZÁRÓLAG FA TÜZELÉS ESETÉN ALKALMAZHATÓ! Cserépkályhához, kandallóhoz
      használható technológia.
      <br />
      <br />
      Bélelés módja hasonló, mint a hagyományos Furan Flex béléscsőé. A
      különbség abból adódik, hogy a bekötőidom és az az alatti szakasz teljes
      egészében korrózióálló acél idomokból van felépítve. A műgyanta cső a
      bekötés felett helyezkedik el.
      <br />
      <br />A kémény megfelelő előkészítése után belehelyezik a műgyanta
      béléscsövet a kéménybe, majd csatlakoztatják a gőzfejlesztő készüléket.
      Folyamatos nyomáson tartva, kb. 3 órán át járatják benne a forró gőzt. Ha
      megfelelően kikeményedett a cső, akkor elzárják a készüléket, majd az alsó
      kalibráló csőnél, és fent a kémény kitorkollásánál elvágják. Szükség
      esetén beteszik a bekötő fölé a köztes csövet, majd kialakítják az alsó
      szakaszt acél idomokkal (Kondenz tisztító idom, összekötő cső, bekötő
      idom, köztes cső, kalibráló cső). Felhelyezik az esővédő sapkát, és
      visszajavítják a falazatot. A kéménybélelés kész.
    </div>
  );
};

const Detail5 = () => {
  return (
    <div>
      Merev falú anyagokkal történő bélelés esetében alaposan meg kell vizsgálni
      a kéményt. Ez a bélelési mód csak teljesen egyenes, elhúzástól,
      szűkítéstől mentes kémények esetében használható. Mivel nem hajlékony, a
      legkisebb szerkezeti hiba is nehézséget okozhat a bélelés során. Elhúzásos
      kéményekbe csak falbontással szerelhető be. A bélelés a kémény aljától a
      tetejéig történik, folyás irányba helyezzük a csöveket a készülék felé a
      kéménybe, 5-6 cm átfedéssel, így zárt rendszert építve ki.
      <br />
      <br />
      <strong>Alumíniumbélelés:</strong>
      <ul>
        <li>Gázkészülékeknél alkalmazható</li>
        <li>6-12 év élettartam</li>
        <li>
          Hőtágulás hatására rések keletkezhetnek a toldásoknál, füstgáz és sav
          szivároghat
        </li>
        <li>
          Kör formája miatt jelentős keresztmetszet csökkenést okoz a kéményben
          béleléskor
        </li>
      </ul>
      <br />
      <strong>Saválló acélbélelés:</strong>
      <ul>
        <li>Ovál vagy kör keresztmetszetű</li>
        <li>Gáz és vegyes tüzelésnél használjuk</li>
        <li>Erősebb, hosszabb élettartamú, mint az alumínium béléscső</li>
        <li>
          Hőtágulás hatására rések keletkezhetnek, a toldásoknál gázszivárgás
          jelentkezhet
        </li>
        <li>Füstgáz lecsapódás jelentős</li>
        <li>
          Választható keresztmetszettel, általában 1 méteres hosszban gyártják
          és szerelik
        </li>
      </ul>
      <br />
      <strong>Flexibilis, rozsdamentes acél és műanyag béléscsövek:</strong>
      <ul>
        <li>
          Elhúzásos kéményeknél használható, gázkészülékek égéstermékének
          elvezetésére
        </li>
        <li>Hosszú méretben is kapható</li>
        <li>Béleléskor könnyen sérülhet</li>
        <li>Nem korrózióálló</li>
        <li>Csak kör keresztmetszetű</li>
        <li>Nagyobb átmérőben nem gyártják</li>
      </ul>
    </div>
  );
};

const Detail6 = () => {
  return (
    <div>
      Szerelt kéményt csakis építésügyi szabványnak megfelelően lehet építeni.
      Utólagos kiépítéshez az egyik legegyszerűbb kivitelezési módszer. Szilárd,
      olaj, és gáztüzelésnél alkalmazható.
      <br />
      <br />
      <strong>Szerkezeti felépítése:</strong>
      <ul>
        <li>belső koracél cső</li>
        <li>hőálló szigetelőanyag</li>
        <li>külső köpeny, koracél, vagy alumínium csőből</li>
      </ul>
      <br />
      <strong>Alkalmazhatósága:</strong>
      <ul>
        <li>
          ha a meglévő kémény szűk átmérője nem felel meg a rákötendő
          készüléknek, és marással sem bővíthető fel a megfelelő méretre
        </li>
        <li>
          ha az új gázkészülék számára nincs felhasználható épített kémény
        </li>
        <li>kivitelezése gyors és tiszta</li>
        <li>készülékcsere esetén magasítható, áthelyezhető</li>
        <li>gyűjtőkémények kiváltásánál is alkalmazhatóak</li>
        <li>használható meglévő falazott kémény magasítására</li>
      </ul>
    </div>
  );
};

const Detail7 = () => {
  return (
    <div>
      Gyűjtőkémény olyan, egymás feletti lakásokon áthaladó kémény, melybe
      lakásonként egy vagy több gázüzemű, átfolyós, fali készülék csatlakozik. A
      gyűjtőkéménybe fűtőkészülék nem csatlakoztatható, csak konyhai és
      fürdőszobai meleg vizet előállító gázkészülékek.
      <br />
      <br />
      A gyűjtőkémény egymásra helyezett beton blokkokból (40x40x25 cm) áll,
      melyekben kör keresztmetszetű nyílás van. A blokkokat egymásra helyezve
      alakítják ki a kéményjáratot. A lyukátmérők emeletenként növekednek,
      175-200-225-250 mm lépcsőkben.
      <br />
      <br />
      A Multikamin-FuranFlex® az adott méretekre készül, átmérője a szükséges
      hosszakban lépcsőzetesen emelkedik.
      <br />
      <br />
      A gyűjtőkémények ma nem felelnek meg a legújabb szabványoknak, de több
      tízezer működik belőlük Kelet-Európában. Az a hibájuk, hogy a gravitáció
      által keletkezett huzat gyenge, sokszor a füstgáz visszafelé, a lakótérbe
      áramlik. A beton blokkok korrodálódnak, rések keletkeznek, tovább rontva
      az áramlási viszonyokat.
      <br />
      <br />
      A Multikamin-FuranFlex® sima belső fala, hőszigetelő képessége jelentősen
      javítja a huzatot. Ezt mérésekkel igazolta az ÉMI mielőtt az alkalmazási
      engedélyt kiadta.
      <br />
      <br />
      Másik megoldás a gyűjtő kémény kiváltása egyedi kéményekké. Lakásonként
      külön kémény kerül beépítésre a gyűjtőkéménybe, így a lakás tulajdonosok
      igényeiknek megfelelő készüléket használhatnak vízük és lakásuk fűtésére.
      <br />
      <br />
      <strong>
        <em>Tetőjárda, kilépők, kibúvók szerelése:</em>
      </strong>
      <br />
      <br />
      A kémények tisztíthatóságának érdekében, szükséges az alsó és felső
      tisztítási pontok kialakítása, hozzáférhetővé tétele. Az alsó tisztítási
      pontot kaminajtóval, vagy ellenőrző idommal szükséges kialakítani. A felső
      tisztítási pont - amely lehet a kitorkollási pont, vagy magasított
      kémények esetében a kürtőbe épített felső ellenőrző idom - elérhetővé
      tétele ugyanolyan fontos, mint az alsó. Ezen okból, minden tetőtípusnál
      (kivétel a lapos tető) szükséges a tetőkibúvó és a fém, csúszásmentes
      tetőjárda, vagy tetőkilépő. Abban az esetben, ha már a meglévő tetőjárda
      elöregedett, és nem járható, szükséges annak cseréje minősített
      tetőjárdára.
      <br />
      <br />
      Kéményseprő szempontból nézve nagyon fontos a tetőjárda kiépítése, mert ha
      nem tud eljutni a kéményhez, a kéményseprő munkáját sem tudja hatékonyan
      ellátni, illetve teljes egészében elvégezni.
    </div>
  );
};
export const ChimneyDetails = props => {
  const [selectedMenu, setSelectedMenu] = useState(1);

  const workDetails = [
    {
      title: "1. Zárt égésterű, kondenzációs gázkészülékek kéménybélelése:",
      content: <Detail1 />
    },
    {
      title: "2. Nyílt égésterű, hagyományos, gravitációs készülékek bélelése",
      content: <Detail2 />
    },
    {
      title: "3. Furan Flex béléscső",
      content: <Detail3 />
    },
    {
      title: "4. Furan Flex RWV béléscső",
      content: <Detail4 />
    },
    {
      title: "5. Alumínium és saválló acélbélelés",
      content: <Detail5 />
    },
    {
      title: "6. Szerelt kémények",
      content: <Detail6 />
    },
    {
      title: "7. Gyűjtőkémények bélelése, kiváltása",
      content: <Detail7 />
    }
  ];

  const goToSection = section => {
    const sectionLink = document.getElementById(`${section}Link`);
    selectedMenu = section;
    sectionLink.selected = true;
  };

  const [openSection, setOpenSection] = useState(0);

  return (
    <WorkPage>
      <WorkDetails>
        <MyName>Kéménybélelési technológiák, készülékek</MyName>
        {workDetails.map((work, index) => (
          <div>
            <WorkType
              onClick={() => setOpenSection(index)}
              isOpenSection={index === openSection}
            >
              {work.title}
            </WorkType>
            <Card isOpenSection={index === openSection}>
              <CardInfoContainer>
                <CardInfo>{work.content}</CardInfo>
              </CardInfoContainer>
            </Card>
          </div>
        ))}
        <FormButton onClick={() => props.goBack(true)}>Vissza</FormButton>
      </WorkDetails>
    </WorkPage>
  );
};
