import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap"
import "./App.css";
import { css, default as styled } from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faGithubSquare,
  faLinkedin,
  faCodepen,
  faReact,
  faJs,
  faAngular,
  faNodeJs,
} from "@fortawesome/free-brands-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { ChimneyDetails } from "./components/ChimneyDetails";
import { Gallery } from "./components/Gallery";
import { News } from "./components/News";
import { OurWork } from "./components/OurWork";
import { Partners } from "./components/Partners";
import background from "./images/background.jpg";
import square from "./images/square.jpg";
import rectengular from "./images/rectengular.jpg";
import horvath from "./images/horvath_pic.jpg";

library.add(
  faFacebookSquare,
  faGithubSquare,
  faLinkedin,
  faCodepen,
  faEnvelope,
  faFile,
  faReact,
  faJs,
  faAngular,
  faNodeJs
);

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: #000;
  position: relative;

  * {
    font-family: "Cabin", sans-serif;
  }
`;

const NavBar = styled.nav`
  padding: 0px !important;
  background: #ffcb0c; /* fallback for old browsers */
  border-bottom: 4px solid rgb(205, 160, 0);
  box-shadow: 0 1px 5px rgba(30, 30, 30, 0.5);
  position: fixed;
  width: 100% !important;
`;

const NavBarContent = styled.div`
  width: 100% !important;
  max-width: 1120px !important;
  padding: 0 60px !important;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    padding: 0 25px !important;
  }
  @media (max-width: 550px) {
    padding: 0 !important;
  }
`;

const NavBarListCont = styled.div`
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;

  @media (max-width: 965px) {
    width: 100%;
  }

  @media (max-width: 550px) {
    width: 100% !important;

    > ul {
      margin-right: auto !important;
    }
  }

  @media (max-width: 400px) {
    align-self: flex-end !important;
  }
`;

const NavBarTitle = styled.a`
  font-family: "Alata", sans-serif;
  font-size: 11px !important;
  transition: color 0.15s ease-in;
  text-transform: uppercase;
  > a {
    cursor: pointer;
    text-decoration: none;
    color: black;

    :hover {
      color: black;
    }
  }

  &.navbar-brand {
    color: rgb(20, 20, 20) !important;
  }

  :hover {
    color: #ffcb0c;
    text-decoration: none;
  }

  @media (max-width: 965px) {
    display: none !important;
  }
`;

const NavList = styled.ul`
  font-family: "Alata", sans-serif;

  @media (max-width: 992px) {
    flex-direction: row !important;
  }
`;

const PageSection = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 76px 60px;
  background: rgb(190, 190, 190); /* fallback for old browsers */
  /*old color: rgb(220, 190, 170) */
  background: -webkit-linear-gradient(
    to right,
    rgb(190, 190, 190) 65%,
    rgba(190, 190, 190, 0.8) 100%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    rgb(190, 190, 190) 65%,
    rgba(190, 190, 190, 0.8) 100%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  @media (max-width: 550px) {
    padding: 76px 30px;
  }
  @media (max-width: 450px) {
    background: rgb(190, 190, 190); /* fallback for old browsers */
  }
`;

const AboutSection = styled.div`
  color: rgb(20, 20, 20);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  @media (max-width: 764px) {
    flex-direction: column;
  }
`;

const Introduction = styled.div`
  font-family: "Cabin", sans-serif;
  flex: 0 1 70%;
  margin-right: 40px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-self: center;
  @media (max-width: 764px) {
    margin-right: 0;
    text-align: justify;
  }
`;

const PortfolioContainer = styled.div`
  background-color: rgb(247, 247, 247);
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 100vh;
  height: auto;
`;

const GalleryContainer = styled(PortfolioContainer)`
  background: rgb(110, 110, 110); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    rgb(110, 110, 110) 65%,
    rgba(110, 110, 110, 0.8) 100%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    rgb(110, 110, 110) 65%,
    rgba(110, 110, 110, 0.8) 100%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  @media (max-width: 450px) {
    background: rgb(110, 110, 110); /* fallback for old browsers */
  }
`;

const PartnerContainer = styled(PortfolioContainer)``;

const SendButton = styled.div`
  padding-top: 10px;

  @media (max-width: 764px) {
    padding-top: 0;
  }
`;

const Contact = styled(AboutSection)`
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
`;

const MyName = styled.h2`
  font-family: "Michroma", sans-serif;
  text-align: left;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 36px;
  background-color: rgb(105, 65, 46);
  background: linear-gradient(
    to right,
    rgb(105, 65, 46) 45%,
    rgba(105, 65, 46, 0) 100%
  );
  margin-bottom: 40px;
  width: 100%;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;

  @media (max-width: 764px) {
    margin-bottom: 10px;
  }
  @media (max-width: 550px) {
    font-size: 28px;
  }

  > a {
    font-size: 50px;
  }
`;

const SubTitle = styled.div`
  font-size: 24px;
  font-style: italic;
`;

const CTASection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 235, 235);
  border-radius: 4px;
  width: 100%;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.5);

  &:hover {
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.9);
    background-color: rgb(240, 240, 240);
  }
`;
const CTA = styled.a`
  color: black;
  font-size: 26px;
  font-weight: 700;
  font-style: italic;
  text-shadow: 1px 1px 5px #ffcb0c;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: black;
  }
`;

const ButtonLinkStyled = css`
  min-height: 40px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffcb0c;
  color: rgb(20, 20, 20);
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 1px 1px 3px rgb(90, 90, 90);
  transition: color 0.15s ease-in;
  transition: background-color 0.1s ease-in;
  font-weight: bold;

  :hover {
    text-decoration: none;
    color: rgb(20, 20, 20);
    background-color: rgb(90, 90, 90);
    box-shadow: 2px 1px 3px rgb(30, 30, 30);
  }
`;

const FormButton = styled.input`
  ${ButtonLinkStyled};
  margin-top: 15px;
  border: none;
  &:first-child {
    margin-right: 15px;
  }

  @media (max-width: 550px) {
    margin-top: 0;
    font-size: 14px;
    padding: 5px 10px;
  }
`;

const MenuLink = styled.a`
  font-family: "Alata", sans-serif;
  text-transform: uppercase;
  color: rgba(10, 10, 10, 0.7) !important;
  font-weight: 700;
  transition: color 0.15s ease-in;
  transition: background-color 0.15s ease-in;
  padding: 0 20px;
  width: 120px;
  font-size: 13px;

  @media (max-width: 992px) {
    padding: 8px !important;
  }
  @media (max-width: 700px) {
    width: 100px;
  }
  @media (max-width: 500px) {
    font-size: 11px;
    width: auto;
    padding: 0 10px;
  }
  :hover {
    color: #ffcb0c !important;
    background-color: rgba(10, 10, 10, 0.8);
    border-radius: 4px;
    border-right: none;
    border-left: none;
    margin-left: -4px;
    margin-right: 4px;
    padding-left: 24px;
  }

  > #contactLink {
    border-right: none !important;
  }
`;

const BorderedLink = styled(MenuLink)`
  border-right: 1px solid rgba(10, 10, 10, 0.7);
`;

const ProfilePic = styled.img`
  width: 50%;
  height: auto;
  object-fit: contain;
  @media (max-width: 764px) {
    display: none;
  }
`;

export const ProfilePicRectangle = styled.img`
  width: 100%;
  height: auto;
  display: none;
  @media (max-width: 764px) {
    margin: 20px 0;
    display: block;
  }
`;

const HorvathPic = styled.img`
  width: 20vw;
  height: 20vw;
  object-fit: contain;
  border-radius: 50%;
  max-width: 250px;
  max-height: 250px;

  @media (max-width: 750px) {
    align-self: center;
    margin-bottom: 40px;
    width: 220px;
    height: 220px;
  }
`;

const ContactColumns = styled.div`
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  @media (max-width: 764px) {
    flex-direction: column;
  }
`;

const ContactSection = styled.div`
  width: 45%;
  text-align: left;
  transition: color 0.15s ease-in;

  @media (max-width: 764px) {
    width: 100%;
    margin-top: 25px;
  }

  > h5 {
    > a {
      color: rgb(20, 20, 20);
      :hover {
        color: #ffcb0c;
      }
    }
    margin-bottom: 20px;
  }
`;

const InputField = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
  > input,
  textarea {
    width: 100%;
    background-color: rgb(240, 240, 240);
    border: none;
  }
`;

const MainContact = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

const PhoneNumbers = styled.div`
  margin-left: 15px;

  @media (max-width: 450px) {
    margin-left: 0;
  }
`;

const PhoneTitle = styled.h5`
  margin-bottom: 5px;
  font-weight: 700;
  margin-right: 10px;
`;

const PhoneName = styled.h6`
  margin: 0;
`;
const PhoneNumber = styled.a`
  color: black;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;

  @media (max-width: 750px) {
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const ContactPeople = styled(ContactInfo)`
  @media (max-width: 750px) {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const EmailTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;

  @media (max-width: 450px) {
    line-height: 1em;
  }
`;

const Email = styled.a`
  color: black;
  font-size: 16px;
`;

const CopyRightBlock = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  height: 16px;
  font-size: 11px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgb(65, 65, 65);
  color: rgb(210, 210, 210);
`;

const NewsButton = styled(Button)`
  ${ButtonLinkStyled};
  border: none;
  width: auto;
  justify-self: flex-start;
  color: rgb(105,65,46) !important;
  font-weight: 700 !important;
  font-size: 20px !important;
`;

function App() {
  let selectedMenu = undefined;
  const [onMainPage, setMainPage] = useState(true);
  const [showNews, setShowNews] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 700) {
      setIsMobile(true)
    }
  }, [])

  const goToSection = (section) => {
    if (onMainPage === false) {
      setMainPage(true);
    }
    const sectionLink = document.getElementById(`${section}Link`);
    selectedMenu = section;
    sectionLink.selected = true;
  };

  const goToChimneyPage = () => {
    setMainPage(false);
    window.scrollTo(0, 0);
  };

  return (
    <Container className="App">
      <NavBar className="navbar navbar-expand-lg sticky-top navbar-dark">
        <NavBarContent className="container">
          <NavBarTitle className="navbar-brand" href="#">
            <a href="mailto:btgflexbau@gmail.com">
              E-mail: btgflexbau@gmail.com
            </a>{" "}
            - <a href="tel:+36706231129">Tel: +36 70 623 1129</a>
          </NavBarTitle>
          <NavBarListCont className="navbar" id="navbarSupportedContent">
            <NavList className="navbar-nav ml-auto">
              <li className="nav-item active menubutt">
                <BorderedLink id="aboutLink" className="nav-link" href="#">
                  Rólunk
                </BorderedLink>
              </li>
              <li className="nav-item menubutt">
                <BorderedLink
                  id="workLink"
                  className="nav-link"
                  href="#work"
                  selected={selectedMenu === "work"}
                  onClick={() => goToSection("work")}
                >
                  Profilunk
                </BorderedLink>
              </li>
              <li className="nav-item active menubutt">
                <BorderedLink
                  id="galleryLink"
                  className="nav-link"
                  href="#gallery"
                  selected={selectedMenu === "gallery"}
                  onClick={() => goToSection("gallery")}
                >
                  Galéria
                </BorderedLink>
              </li>
              <li className="nav-item menubutt">
                <BorderedLink
                  id="partnersLink"
                  className="nav-link"
                  href="#partners"
                  selected={selectedMenu === "partners"}
                  onClick={() => goToSection("partners")}
                >
                  Partnereink
                </BorderedLink>
              </li>
              <li className="nav-item menubutt">
                <MenuLink
                  id="contactLink"
                  className="nav-link"
                  href="#contact"
                  selected={selectedMenu === "contact"}
                  onClick={() => goToSection("contact")}
                >
                  Kapcsolat
                </MenuLink>
              </li>
            </NavList>
          </NavBarListCont>
        </NavBarContent>
      </NavBar>
      {onMainPage && (
        <>
          <PageSection>
            <AboutSection>
              <Introduction>
                <MyName>
                  <>BTG Kamin Flex BAU Kft.</>
                </MyName>
                <SubTitle>
                  Kéménytechnika · Épületgépészet
                </SubTitle>
                <br />
                <br />
                Cégünk kazáncserékkel, kémények bélelésével, víz-,
                fűtésszereléssel, tetőjárdák kiépítésével és mindezek
                szakhatósági átadásával foglalkozik.
                <br />
                <br />
                <CTASection>
                  <CTA href="tel:+36706231129">
                    <strong>Ingyenes felmérés és árajánlat készítés!</strong>
                    <br /> Tel: +36 70 623 1129
                  </CTA>
                </CTASection>
                <NewsButton className="mt-3" onClick={() => setShowNews(true)} variant="warning">
                  Újdonságok!
                </NewsButton>
              </Introduction>
              <ProfilePic
                src={square}
                alt="illustration picture"
                width="200px"
              />
              <ProfilePicRectangle
                src={rectengular}
                alt="illustration picture"
              />
            </AboutSection>
          </PageSection>
          <PortfolioContainer id="work">
            <OurWork goToChimneyPage={goToChimneyPage} />
          </PortfolioContainer>
          <GalleryContainer id="gallery">
            <Gallery />
          </GalleryContainer>
          <PartnerContainer id="partners">
            <Partners />
          </PartnerContainer>
          <PageSection id="contact">
            <Contact>
              <MyName>Kapcsolat, ajánlatkérés, felmérés</MyName>
              <hr />
              <ContactColumns>
                <ContactSection>
                  <MainContact>
                    <HorvathPic
                      src={horvath}
                      alt="photo of Horvath Beata and Horvath MArk"
                    />
                    <PhoneNumbers>
                      <ContactPeople>
                        <PhoneTitle>Kéménytechnika</PhoneTitle>
                        <PhoneName>Horváth Beáta</PhoneName>
                      </ContactPeople>
                      <ContactPeople>
                        <PhoneTitle>Épületgépészet</PhoneTitle>
                        <PhoneName>Horváth Márk</PhoneName>
                      </ContactPeople>
                      <ContactInfo>
                        <EmailTitle>Telefon: </EmailTitle>
                        <Email href="tel:+36706231129">+36 70 623 1129 </Email>
                      </ContactInfo>
                      <ContactInfo>
                        <EmailTitle>E-mail: </EmailTitle>
                        <Email href="mailto:btgflexbau@gmail.com">
                          btgflexbau@gmail.com
                        </Email>
                      </ContactInfo>
                    </PhoneNumbers>
                  </MainContact>
                </ContactSection>
                <ContactSection>
                  <form
                    action="https://mailthis.to/btgflexbau@gmail.com"
                    method="POST"
                  >
                    <InputField>
                      Név:
                      <input type="text" name="name" required />
                    </InputField>
                    <InputField>
                      E-mail:
                      <input type="email" name="_replyto" required />
                    </InputField>
                    <InputField>
                      Üzenet:
                      <textarea
                        id="exampleTextarea"
                        rows="10"
                        type="text"
                        name="message"
                        required
                      />
                    </InputField>
                    <SendButton>
                      <FormButton type="submit" value="Küldés" />
                      <FormButton type="reset" value="Újrakezd" />
                    </SendButton>
                  </form>
                </ContactSection>
              </ContactColumns>
            </Contact>
          </PageSection>
        </>
      )}
      {!onMainPage && <ChimneyDetails goBack={setMainPage} />}
      <CopyRightBlock>2021 ® BTG Kamin Flex BAU Kft.</CopyRightBlock>
      <News isMobile={isMobile} setShow={setShowNews} show={showNews} />
    </Container>
  );
}

export default App;
