import React, { useState } from "react";
import { css, default as styled } from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faGithubSquare,
  faLinkedin,
  faCodepen,
  faReact,
  faJs,
  faAngular,
  faNodeJs,
} from "@fortawesome/free-brands-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FutesRendszerMosas } from "../content/futesrendszer-mosas"

library.add(
  faFacebookSquare,
  faGithubSquare,
  faLinkedin,
  faCodepen,
  faEnvelope,
  faFile,
  faReact,
  faJs,
  faAngular,
  faNodeJs
);

const Portfolio = styled.div`
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 76px 60px 40px;
  width: 100%;
  max-width: 1120px;
  @media (max-width: 550px) {
    padding: 76px 30px;
  }
`;

const MyName = styled.h2`
  font-family: "Michroma", sans-serif;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 36px;
  background-color: rgb(105, 65, 46);
  background: linear-gradient(
    to right,
    rgb(105, 65, 46) 45%,
    rgba(105, 65, 46, 0) 100%
  );
  margin-bottom: 40px;
  width: 100%;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;

  @media (max-width: 764px) {
    margin-bottom: 10px;
  }
  @media (max-width: 550px) {
    font-size: 28px;
  }

  > a {
    font-size: 50px;
  }
`;

const Card = styled.div`
  width: 100%;
  background-color: rgb(240, 240, 240);
  border-radius: 4px;
  padding: 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  transition: background-color 0.1s ease-in;
  box-shadow: 1px 0 10px rgba(30, 30, 30, 0.8);

  :hover {
    background-color: rgb(250, 250, 250);
  }
`;

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  height: 100%;

  > h5 {
    font-weight: 600;
  }
`;

const WorkTypes = styled.div`
  display: flex;
  flex-direction: row;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: auto;
  background-color: rgb(100, 100, 100);
  align-self: flex-start;
  @media (max-width: 620px) {
    width: 100%;
    flex-direction: column;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const WorkType1 = styled.h5`
  padding: 15px 25px;
  margin: 0;
  line-height: 1em;
  color: ${(p) => (p.selected ? "#ffcb0c" : "rgb(240, 240, 240)")};
  background-color: ${(p) =>
    p.selected ? "rgb(70, 70, 70)" : "rgb(100, 100, 100)"};
  border-top-left-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  :hover {
    background-color: rgb(120, 120, 120);
    color: #ffcb0c;
  }

  @media (max-width: 620px) {
    width: 100%;
    border-top-left-radius: 0;
  }
`;

const WorkType2 = styled(WorkType1)`
  border-left: 1px solid #ffcb0c;
  border-top-left-radius: 0;
  border-top-right-radius: 5px;

  @media (max-width: 620px) {
    border-left: none;
    border-top: 1px solid #ffcb0c;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const ButtonLinkStyled = css`
  min-height: 40px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffcb0c;
  color: rgb(20, 20, 20);
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 1px 1px 3px rgb(90, 90, 90);
  transition: color 0.15s ease-in;
  transition: background-color 0.1s ease-in;
  cursor: pointer;

  :hover {
    text-decoration: none;
    color: #ffcb0c;
    background-color: rgb(90, 90, 90);
    box-shadow: 2px 1px 3px rgb(30, 30, 30);
  }
`;

const FormButton = styled.div`
  ${ButtonLinkStyled};
  margin-top: 15px;
  border: none;
  width: auto;
  justify-self: flex-start;
  font-weight: bold;

  &:first-child {
    margin-right: 15px;
  }
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

const WorkList = styled.ul`
  padding-left: 15px;
  width: 100%;
`;

export const OurWork = (props) => {
  const [selectedMenu, setSelectedMenu] = useState(1);

  const goToSection = (section) => {
    const sectionLink = document.getElementById(`${section}Link`);
    selectedMenu = section;
    sectionLink.selected = true;
  };

  return (
    <Portfolio>
      <MyName>Profilunk&nbsp;</MyName>
      <WorkTypes>
        <WorkType1
          onClick={() => setSelectedMenu(1)}
          selected={selectedMenu === 1}
        >
          Kéménytechnika{" "}
        </WorkType1>
        <WorkType2
          onClick={() => setSelectedMenu(2)}
          selected={selectedMenu === 2}
        >
          Épületgépészet{" "}
        </WorkType2>
      </WorkTypes>
      <Card>
        <CardInfoContainer>
          {selectedMenu === 1 && (
            <CardInfo>
              A tüzelőberendezések működése során keletkezett égésterméket
              többfajta módon és kéményrendszerrel vezethetjük a szabadba.
              <br />
              <br />A kémény bélelésének módját meghatározza a tüzelőberendezés
              működési elve, a tüzelőanyag, amivel fűtünk, a kályha vagy kazán
              fajtája, típusa, hőteljesítménye, a távozó füstgáz, égéstermék
              hőmérséklete. <br />
              <br />A tüzelőanyagok égetése folyamán savas vízgőz keletkezik,
              ami lecsapódik a kémények belsejében, tönkre téve a vakolatot,
              betont, téglát és az alumínium bélelést is. Az így keletkezett
              részeken szénmonoxid juthat a lakótérbe, mely életveszélyes lehet.{" "}
              <br />
              <br />A korszerű kéménynek (kéménybélelésnek) számos jó
              tulajdonsággal kell rendelkeznie: a modern fűtési technológiának
              megfelelő, tűzbiztos, füsttömör, hőszigetelt, saválló, hosszú
              élettartamú, ha kell koromégésnek ellenálló, nedvességre
              érzéketlen, hogy elkerüljük a belső lecsapódás során keletkezett
              kondenzátum okozta átnedvesedést és az ebből eredő állagromlást.
              Az ilyen kémény, kéménybélelés évtizedeken át megbízhatóan fog
              működni. <br />
              <br />A kémények bélelése nem szezonális jellegű. Télen, nyáron,
              hóban, fagyban is dolgozunk, a folyamat általában egy napot vesz
              igénybe, így lakása a hideg hónapokban sem hűl le a kivitelezés
              ideje alatt.
              <br />
              <br />
              <FormButton onClick={() => props.goToChimneyPage()}>
                Tudjon meg többet!
              </FormButton>
            </CardInfo>
          )}
          {selectedMenu === 2 && (
            <CardInfo>
              <WorkList>
                <li>Kondenzációs kazános rendszer tervezése, kivitelezése</li>
                <li>Víz-, fűtés- és csatornahálózat tervezése, kivitelezése</li>
                <li>Radiátor csere, áthelyezés, légtelenítés</li>
                <li>
                  Szerelvények cseréje, beépítése (csaptelep, szifon, szűrő)
                </li>
                <li>
                  Szaniterek felszerelése, bekötése, cseréje (mosdó,
                  zuhanykabin, zuhanytálca, kád, WC csésze és tartály, bidé,
                  mosógép, mosogatógép)
                </li>
                <li>Fürdőszoba, fűtéshálózat átalakítása</li>
                <li>Bojler bekötés</li>
                <li>Csőtörés elhárítás</li>
                <li>Vízóracsere</li>
                <li>Locsoló vízmérő kiépítése</li>
                <li>
                  Igény szerint a kiválasztott termék beszerzése, szállítása
                </li>
                <li>Egyszerűsített (nem tervköteles) készülékcsere</li>
                <li>Kazánbeüzemelés</li>
                <br />
                <FutesRendszerMosas />
              </WorkList>
            </CardInfo>
          )}
        </CardInfoContainer>
      </Card>
    </Portfolio>
  );
};
