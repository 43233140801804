import React, { useState } from "react";
import { default as styled } from "styled-components";

const Portfolio = styled.div`
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 76px 60px 40px;
  width: 100%;
  max-width: 1120px;
  @media (max-width: 550px) {
    padding: 76px 30px;
  }
`;

const MyName = styled.h2`
  font-family: "Michroma", sans-serif;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 36px;
  background-color: rgb(105, 65, 46);
  background: linear-gradient(
    to right,
    rgb(105, 65, 46) 45%,
    rgba(105, 65, 46, 0) 100%
  );
  margin-bottom: 40px;
  width: 100%;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;

  @media (max-width: 764px) {
    margin-bottom: 20px;
  }
  @media (max-width: 550px) {
    font-size: 28px;
  }

  > a {
    font-size: 50px;
  }
`;

const PartnerRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  @media (max-width: 640px) {
    padding-bottom: 50px;
  }
`;

const PartnerBlock = styled.a`
  width: 250px;
  height: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: black;
  :hover {
    color: black;
  }
  cursor: pointer;

  @media (max-width: 640px) {
    width: 190px;
    height: 260px;
  }
`;

const LightPartnerImage = styled.img`
  width: 240px;
  min-height: 240px;
  object-fit: contain;
  background-color: rgba(30, 30, 30);
  padding: 20px;
  margin-bottom: 10px;

  @media (max-width: 640px) {
    width: 180px;
    min-height: 180px;
  }
`;

const DarkPartnerImage = styled(LightPartnerImage)`
  background-color: rgba(255, 255, 255);
`;

const PartnerName = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

const PartnerUrl = styled.a`
  font-size: 13px;
  color: rgb(205, 160, 0);

  :hover {
    color: #ffcb0c;
  }
`;

export const Partners = props => {
  const [selectedMenu, setSelectedMenu] = useState(1);

  const goToSection = section => {
    const sectionLink = document.getElementById(`${section}Link`);
    selectedMenu = section;
    sectionLink.selected = true;
  };

  const partners = [
    {
      title: "TRICOX",
      imageUrl: "https://tricox.hu/img/tricox_logo.png",
      darkImage: false,
      url: "https://tricox.hu/"
    },
    {
      title: "Thermoplus-2005 Kft.",
      imageUrl:
        "https://thermopluskemeny.hu/wp-content/uploads/2018/09/thermo-vector.svg",
      darkImage: false,

      url: "https://thermopluskemeny.hu/"
    },
    {
      title: "Ép-GéPéSZ Holding Kft.",
      imageUrl: "https://gepesz.hu/images/gepesz-holding-epuletgepsz-logo.png",
      darkImage: false,
      url: "https://gepesz.hu/"
    },
    {
      title: "KÉMÉNYHÁZ KFT",
      imageUrl: "https://media.cylex.hu/companies/6752/54/logo/logo.jpg",
      darkImage: false,
      url: "http://www.kemenyhaz.hu/"
    },
    {
      title: "Nagy-Kel Trade Kft.",
      imageUrl: "http://nova-globus.hu/rw_common/images/rwsitelogo.jpg",
      darkImage: false,
      url: "http://nova-globus.hu/"
    },
    {
      title: "KÉMÉNYMŰHELY",
      imageUrl:
        "https://kemenymuhely.hu/wp-content/uploads/2016/05/Kemenymuhely-logo-new-mini-1.png",
      darkImage: true,
      url: "https://kemenymuhely.hu/"
    },
    {
      title: "Ventil Épületgépészeti Kft.",
      imageUrl:
        "https://www.ventil.hu/assets/general/images/kk/xkk-logo.png.pagespeed.ic.iPvugYab6n.webp",
      darkImage: true,
      url: "https://www.ventil.hu/"
    },
    {
      title: "KOMPOZITOR Műanyagipari Fejlesztő Kft.",
      imageUrl:
        "http://kompozitor.hu/wp-content/uploads/2018/03/kompozitor_logo_dark-e1522014400853.png",
      darkImage: true,
      url: "http://kompozitor.hu/"
    }
  ];

  return (
    <Portfolio>
      <MyName>Partnereink&nbsp;</MyName>
      <PartnerRow>
        {partners.map(partner => (
          <PartnerBlock href={partner.url} target="_blank">
            {partner.darkImage ? (
              <LightPartnerImage src={partner.imageUrl} />
            ) : (
              <DarkPartnerImage src={partner.imageUrl} />
            )}
            <PartnerName>{partner.title}</PartnerName>
            <PartnerUrl href={partner.url} target="_blank">
              {partner.url}
            </PartnerUrl>
          </PartnerBlock>
        ))}
      </PartnerRow>
    </Portfolio>
  );
};
