import React, { useState } from "react";
import { css, default as styled } from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faGithubSquare,
  faLinkedin,
  faCodepen,
  faReact,
  faJs,
  faAngular,
  faNodeJs,
} from "@fortawesome/free-brands-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { kemenyPhotos } from "../images/kemeny";
import { gepeszPhotos } from "../images/gepesz";
import { GalleryDialog } from "./Gallery-Dialog";

library.add(
  faFacebookSquare,
  faGithubSquare,
  faLinkedin,
  faCodepen,
  faEnvelope,
  faFile,
  faReact,
  faJs,
  faAngular,
  faNodeJs,
  faSearchPlus
);

const Portfolio = styled.div`
  position: relative;
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 76px 60px 40px;
  width: 100%;
  max-width: 1120px;
  @media (max-width: 550px) {
    padding: 76px 30px;
  }
`;

const MyName = styled.h2`
  font-family: "Michroma", sans-serif;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 36px;
  background-color: rgb(90, 55, 40);
  background: linear-gradient(
    to right,
    rgb(90, 55, 40) 45%,
    rgba(90, 55, 40, 0) 100%
  );
  margin-bottom: 40px;
  width: 100%;
  color: rgb(230, 230, 230);
  padding: 10px 20px;
  border-radius: 4px;

  @media (max-width: 764px) {
    margin-bottom: 10px;
  }
  @media (max-width: 550px) {
    font-size: 28px;
  }

  > a {
    font-size: 50px;
  }
`;

const Card = styled.div`
  width: 100%;
  background-color: rgb(240, 240, 240);
  border-radius: 4px;
  padding: 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  transition: background-color 0.1s ease-in;
  box-shadow: 1px 0 10px rgba(30, 30, 30, 0.8);

  :hover {
    background-color: rgb(250, 250, 250);
  }
`;

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  height: 100%;

  > h5 {
    font-weight: 600;
  }
`;

const WorkTypes = styled.div`
  display: flex;
  flex-direction: row;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: auto;
  background-color: rgb(100, 100, 100);
  align-self: flex-start;
  @media (max-width: 400px) {
    width: 100%;
    flex-direction: column;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const WorkType1 = styled.h5`
  padding: 15px 25px;
  margin: 0;
  line-height: 1em;
  color: ${(p) => (p.selected ? "#ffcb0c" : "rgb(240, 240, 240)")};
  background-color: ${(p) =>
    p.selected ? "rgb(70, 70, 70)" : "rgb(100, 100, 100)"};
  border-top-left-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  :hover {
    background-color: rgb(120, 120, 120);
    color: #ffcb0c;
  }

  @media (max-width: 400px) {
    width: 100%;
    border-top-left-radius: 0;
  }
`;

const WorkType2 = styled(WorkType1)`
  border-left: 1px solid #ffcb0c;
  border-top-left-radius: 0;
  border-top-right-radius: 5px;

  @media (max-width: 400px) {
    border-left: none;
    border-top: 1px solid #ffcb0c;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const ButtonLinkStyled = css`
  min-height: 40px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffcb0c;
  color: rgb(20, 20, 20);
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 1px 1px 3px rgb(90, 90, 90);
  transition: color 0.15s ease-in;
  transition: background-color 0.1s ease-in;
  cursor: pointer;

  :hover {
    text-decoration: none;
    color: #ffcb0c;
    background-color: rgb(90, 90, 90);
    box-shadow: 2px 1px 3px rgb(30, 30, 30);
  }
`;

const PhotoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 400px;
  @media (max-width: 870px) {
    flex-direction: column;
    height: calc(45vw + 120px);
  }
  @media (max-width: 825px) {
    height: calc(45vw + 100px);
  }
`;

const MainPhotoContainer = styled.div`
  position: relative;
  width: 50%;
  height: 390px;
  @media (max-width: 870px) {
    width: calc(100vw - 187px);
    height: 45vw;
  }
  @media (max-width: 550px) {
    width: calc(100vw - 125px);
  }
`;

const MainPhoto = styled.img`
  width: 100%;
  height: 390px;
  border: 1px solid black;
  object-fit: contain;
  background-color: black;
  cursor: pointer;
  @media (max-width: 870px) {
    height: 100%;
  }
  @media (max-width: 550px) {
    object-fit: cover;
  }
`;

const PhotoList = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  margin: -5px 5px;
  box-sizing: border-box;
  justify-content: flex-end;
  @media (max-width: 870px) {
    flex-wrap: nowrap;
    height: 120px;
    margin-top: 10px;
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100%;
    justify-content: flex-start;
    margin: 0;
  }
  @media (max-width: 825px) {
    height: 100px;
    margin-top: 5px;
  }
`;

const PhotoSlider = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media (max-width: 870px) {
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: auto;
    height: 100%;
  }
`;

const ThumbnailPhoto = styled.img`
  width: 120px;
  height: 90px;
  border: ${(p) =>
    p.selected ? "3px solid #ffcb0c" : "3px solid transparent"};
  object-fit: cover;
  margin: 5px;
  cursor: pointer;

  :hover {
    border: 3px solid #ffcb0c;
  }

  @media (max-width: 825px) {
    height: 65px;
    width: 90px;
  }
`;

const ZoomIconContainer = styled.div`
  width: 50px;
  height: 50px;
  background-color: black;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  border-top-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;

  :hover {
    > svg {
      color: #ffcb0c;
    }
  }
  @media (max-width: 825px) {
    width: 35px;
    height: 35px;
    border-top-left-radius: 3px;
  }
`;

const ZoomIcon = styled(FontAwesomeIcon)`
  color: #666869;
  font-size: 28px;

  @media (max-width: 825px) {
    font-size: 20px;
  }
`;

export const Gallery = (props) => {
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [photoList, setPhotoList] = useState(kemenyPhotos);
  const [selectedPhoto, setSelectedPhoto] = useState(kemenyPhotos[0]);
  const [isModalActive, setModal] = useState(false);
  const body = document.querySelector("body");

  const setWorkType = (selection) => {
    setSelectedMenu(selection);
    if (selection === 1) {
      setPhotoList(kemenyPhotos);
      setSelectedPhoto(kemenyPhotos[0]);
    } else if (selection === 2) {
      setPhotoList(gepeszPhotos);
      setSelectedPhoto(gepeszPhotos[0]);
    } 
  };

  const openDialog = () => {
    setModal(true);
    setSelectedPhoto(selectedPhoto);
    body.style.overflow = "hidden";
  };

  const closeDialog = () => {
    setModal(false);
    body.style.overflow = "auto";
  };

  return (
    <Portfolio>
      <MyName>Galéria&nbsp;</MyName>
      <WorkTypes>
        <WorkType1 onClick={() => setWorkType(1)} selected={selectedMenu === 1}>
          Kéménytechnika{" "}
        </WorkType1>
        <WorkType2 onClick={() => setWorkType(2)} selected={selectedMenu === 2}>
          Épületgépészet{" "}
        </WorkType2>
      </WorkTypes>
      <Card>
        <CardInfoContainer>
          <PhotoContainer>
            <MainPhotoContainer>
              <MainPhoto src={selectedPhoto} onClick={() => openDialog()} />
              <ZoomIconContainer onClick={() => openDialog()}>
                <ZoomIcon icon={["fas", "search-plus"]} />
              </ZoomIconContainer>
            </MainPhotoContainer>
            <PhotoList>
              {selectedMenu === 1 && (
                <PhotoSlider>
                  {kemenyPhotos.map((photo, index) => (
                    <ThumbnailPhoto
                      src={photo}
                      onClick={() => setSelectedPhoto(photo)}
                      selected={selectedPhoto === photo}
                    />
                  ))}
                </PhotoSlider>
              )}
              {selectedMenu === 2 && (
                <PhotoSlider>
                  {gepeszPhotos.map((photo, index) => (
                    <ThumbnailPhoto
                      src={photo}
                      onClick={() => setSelectedPhoto(photo)}
                      selected={selectedPhoto === photo}
                    />
                  ))}
                </PhotoSlider>
              )}
            </PhotoList>
          </PhotoContainer>
        </CardInfoContainer>
      </Card>
      {isModalActive && (
        <GalleryDialog
          show={isModalActive}
          onClose={() => closeDialog()}
          photoToShow={selectedPhoto}
          photos={photoList}
        />
      )}
    </Portfolio>
  );
};
