import React from "react";
import "../App.css";
import { Offcanvas } from "react-bootstrap";
import { default as styled } from "styled-components";
import { FutesRendszerMosas } from "../content/futesrendszer-mosas"
import "bootstrap/dist/css/bootstrap.min.css";

const Header = styled(Offcanvas.Header)`
  background: linear-gradient(to right, #ffcb0c 45%, transparent 100%);
  color: rgb(105, 65, 46);
`;

const Canvas = styled(Offcanvas)`
padding-bottom: 20px;
  height: ${p => p.isMobile ? '55vh !important' : '100%'};
`;

export const News = (props) => {
  const handleClose = () => props.setShow(false);

  return (
    <Canvas isMobile={props.isMobile} placement={props.isMobile ? 'bottom' : 'end'} show={props.show} onHide={handleClose}>
      <Header closeButton>
        <Canvas.Title>Újdonságok</Canvas.Title>
      </Header>
      <Canvas.Body>
       <FutesRendszerMosas inNews />
      </Canvas.Body>
    </Canvas>
  );
};
